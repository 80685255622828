<template>
  <div v-if="ready" class="w-full animate-fade-in">
    <header class="px-1">
      <div
        v-if="scheduleData"
        class="flex justify-between items-center"
      >
        <button
          class="flex flex-nowrap relative overflow-hidden pl-1"
          :class="{'mask-gradient-left' : activeMode.id === 'ranking', 'mask-gradient-right' : activeMode.id === 'position'}"
          @click="switchMode()"
        >
          <div
            class="uppercase font-bold tracking-wider leading-none flex flex-nowrap relative transition-all"
            :class="{'translate-x-0 left-0' : activeMode.id === 'ranking', '-translate-x-full left-full' : activeMode.id === 'position'}"
          >
            <div
              v-for="(item, index) in modes"
              :key="index"
              class="uppercase tracking-wider text-sm transition-all whitespace-nowrap px-1 py-2"
              :class="item.active ? 'text-white' : 'text-slate-100'"
            >
              {{ item.name }}
            </div>
          </div>
        </button>

        <div class="flex items-center">
          <LeaderboardCountdown
            :end-time="scheduleData?.endTime"
            class="mr-0.5"
            @ended="scheduleEnded"
          />

          <button
            title="Leaderboard information"
            class="icon-ico-info text-lg rounded-full w-5 h-5 leading-none shrink-0 lg:h-full transition-colors bg-slate-500 hover:bg-slate-200"
            @click="openInfoModal()"
          />
        </div>
      </div>
    </header>

    <div ref="container">
      <section
        v-if="(activeMode.id === 'position' && !isPlayerInEntries) || !entries.length"
        class="flex items-center justify-center animate-fade-in"
        :style="`min-height: ${containerMinHeight}px`"
      >
        <p class="text-white text-center text-sm">
          {{ !entries.length ? `No players yet... why not be the first one!` : `You haven't entered the leaderboard yet` }}
        </p>
      </section>

      <section
        v-else-if="entries.length > 0"
        class="animate-fade-in relative"
      >
        <div
          v-if="showHeaders"
          class="flex px-3 py-1 text-3xs uppercase text-green-500 font-bold sm:text-xs"
        >
          <div class="w-1/12">
            <span class="hidden sm:inline-block">Rank</span>
          </div>
          <div class="w-4/12">Player</div>
          <div class="w-5/12">Prize</div>
          <div class="w-2/12 text-right">{{ pointsHeader }}</div>
        </div>
        <transition-group name="list-position">
          <LeaderboardEntry
            v-for="item in entries"
            :key="item.displayName"
            :item="item"
            show-prize
            show-progress
            :display-type="pointDisplayType"
            :decimal-places="pointDisplayDecimals"
            :metadata="metadata"
          />
        </transition-group>
      </section>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  tournament: {
    type: Object,
    default: null,
  },
  pollingBlock: {
    type: Boolean,
    default: false,
  },
  triggerUpdate: {
    type: Boolean,
    default: false,
  },
  showHeaders: {
    type: Boolean,
    default: false,
  },
});

const { $api, $rollbar, } = useNuxtApp();

const uiStore = useUiStore();
const { openInfoCmsModal, } = uiStore;

const Container = ref();
const leaderboardId = ref(null);
const leaderboardData = ref(null);
const scheduleData = ref(null);
const ready = ref(false);
const isPlayerInEntries = ref(true);
const poller = ref(null);
const take = ref(3);
const skip = ref(0);
const aroundCount = ref(1);
const containerMinHeight = ref(0);
const scheduleTimeout = ref(null);
const modes = ref([
  {
    name: 'Leaderboard',
    id: 'ranking',
    active: true,
  },
  {
    name: 'Your Position',
    id: 'position',
    active: false,
  },
]);

const activeMode = computed(() => modes.value.find(e => e.active === true));
const leaderboardScheduleName = computed(() => props.tournament.metadata?.leaderboardScheduleName || false);
const prizes = computed(() => leaderboardData.value?.prizes || []);
const pointDisplayType = computed(() => leaderboardData.value?.pointDisplayType || 'Standard');
const pointDisplayDecimals = computed(() => leaderboardData.value?.pointDisplayDecimals || 0);
const metadata = computed(() => leaderboardData.value?.metadata);
const pointsHeader = computed(() => metadata.value?.pointsHeader || 'Score');
const entries = computed(() => {
  let entriesLocal = leaderboardData.value?.entries;
  if (!entriesLocal || !entriesLocal.length) { return []; }

  // Distribute prizes to entriesLocal
  entriesLocal = entriesLocal.map(e => ({
    ...e,
    prizes: [],
  }));

  prizes.value.forEach((prize) => {
    for (let rank = prize.startRank; rank <= prize.endRank; rank++) {
      const entryIdx = entriesLocal.findIndex(entry => entry.rank === rank);
      if (entryIdx > -1) {
        entriesLocal[entryIdx].prizes.push(prize);
      }
    }
  });
  return entriesLocal;
});

init();

async function init() {
  if (leaderboardScheduleName.value) {
    scheduleData.value = await fetchLeaderboardScheduleItem(leaderboardScheduleName.value);
    leaderboardId.value = scheduleData.value.leaderboardId;
  } else if (props.tournament.leaderboardId) {
    leaderboardId.value = props.tournament.leaderboardId;
  }

  // Load leaderboard data
  if (leaderboardId.value) {
    await fetchLeaderboard(skip.value, take.value);
    ready.value = true;
    startPolling();
  }
}

async function fetchLeaderboardScheduleItem(leaderboardScheduleName) {
  try {
    const data = await $api(`/leaderboard/schedule/${leaderboardScheduleName}/item`, { method: 'GET', });
    return data;
  } catch (err) {
    $rollbar.error('method failed', err);
  }
}

async function fetchLeaderboard() {
  let url = '';
  if (activeMode.value.id === 'ranking') {
    url = `/leaderboard/${leaderboardId.value}?&skip=${skip.value}&take=${take.value}`;
  } else if (activeMode.value.id === 'position') {
    url = `/leaderboard/${leaderboardId.value}/around?count=${aroundCount.value}`;
  }

  try {
    const data = await $api(url, { method: 'GET', });
    // let data = await $fetch(`/data/minigame.json`, { method: 'GET'}) // Testing data
    leaderboardData.value = data;
  } catch (err) {
    $rollbar.error('method failed', err);
  }
}

function startPolling() {
  if (!poller.value) {
    poller.value = setInterval(() => {
      if (!props.pollingBlock) {
        fetchLeaderboard();
      }
    }, 5000);
  }
}

function switchMode() {
  const id = activeMode.value.id === 'ranking' ? 'position' : 'ranking';

  containerHeightUpdate();
  modes.value = modes.value.map(e => ({
    ...e,
    active: id === e.id,
  }));

  fetchLeaderboard();
  isPlayerInEntries.value = true;
  destroyPoller();
  startPolling();
}

function destroyPoller() {
  if (poller.value) {
    clearInterval(poller.value);
    poller.value = null;
  }
}

function containerHeightUpdate() {
  const newH = Container.value?.offsetHeight;
  if (newH > containerMinHeight.value) {
    containerMinHeight.value = newH;
  }
}

function openInfoModal() {
  if (leaderboardScheduleName.value) {
    openInfoCmsModal({ modalId: leaderboardScheduleName.value, });
  }
}

function scheduleEnded() {
  destroyPoller();
  scheduleTimeout.value = setTimeout(() => {
    ready.value = false;
    init();
  }, 15000);
}

watch(
  () => props.triggerUpdate,
  () => {
    fetchLeaderboard();
  }
);

watch(
  () => entries.value,
  (newVal) => {
    isPlayerInEntries.value = newVal.find(e => e.isPlayer) || false;
  }
);

onBeforeUnmount(() => {
  destroyPoller();
  if (scheduleTimeout.value) {
    clearTimeout(scheduleTimeout.value);
    scheduleTimeout.value = null;
  }
});
</script>
